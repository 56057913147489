import React, { useState } from "react";
import TreeComponent from "../TreeComponent/TreeComponent";
import CustomForm from "./CustomForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { reactAppFormExecutionVP } from "../../../services/API";
import { componentType } from "../../common/model/Model";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const FormComponent = ({ configObj, appSessionId, isEdit = false }) => {
  const [formData, setFormData] = useState({});
  const [submitResponseLoading, setSubmitResponseLoading] = useState(false);
  const history = useHistory();
  const params = useParams();

  const submitButtonClick = () => {
    let pageFields = [
      configObj.data.page.page_fields[0]?.map((field) => {
        return {
          is_required: field.is_required,
          display_name: field?.display_name,
          name: field?.name,
        };
      }),
    ];
    let form_data = { ...formData };
    if (!form_data?.user_id) {
      form_data.user_id = JSON.parse(localStorage.getItem("userid"));
    }
    let bodyObject = {
      data: {
        page_fields: pageFields,
        formData: [form_data],
        logic_to_send: configObj.data.logic_to_send,
      },
      app_session_id: appSessionId,
      flow_id: configObj.data.flow_id,
      page_type: componentType.formComponent,
      store_output_key: configObj.data.page.store_output_key,
      tenant_id: JSON.parse(localStorage.getItem("tenantId")),
      user_id: parseInt(JSON.parse(localStorage.getItem("userid"))),
      role_name: JSON.parse(localStorage.getItem("role")),
      sessionid: JSON.parse(localStorage.getItem("session_id")),
    };
    setSubmitResponseLoading(true);
    reactAppFormExecutionVP(bodyObject)
      .then((response) => {
        if (response) {
          toast.success(response.message);
          setFormData({});
          redirectToTransition();
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      })
      .finally(() => {
        setSubmitResponseLoading(false);
      });
  };

  const redirectToTransition = () => {
    const { pageTransition } = configObj.data.page;
    const { submitSuccess } = pageTransition || {};
    if (pageTransition && submitSuccess) {
      if (submitSuccess.transitionType === "appDashboard") {
        history.push("/app-dashboard");
      } else if (submitSuccess.transitionType === "anotherApp") {
        history.push(
          `/app_ui/${submitSuccess.transitionApp}/${submitSuccess.transitionPage}`
        );
      } else if (submitSuccess.transitionType === "anotherPage") {
        history.push(`/app_ui/${params.id}/${submitSuccess.transitionPage}`);
      }
    }
  };

  return (
    <div className="row">
      {configObj.data.page?.showHierarchyTree === "Yes" && (
        <div className="col-md-5 mb-4 px-4">
          <TreeComponent
            isResponseLoading={submitResponseLoading}
            addEdit={true}
            appSessionId={appSessionId}
            treeConfig={configObj.data.page?.hierarchyTreeConfig}
            onSelectChange={(e) => {
              let data = Object.keys(e).length > 0 ? e : {};
              setFormData({
                ...formData,
                ...data,
              });
            }}
          />
        </div>
      )}

      <div
        className={`mb-2 ${
          configObj.data.page?.showHierarchyTree === "Yes"
            ? "col-md-7"
            : "row"
        }`}
      >
        <div className="main-content bg-white py-4 px-4 mb-2 ms-2 col-md-12">
          <CustomForm
            formGroupData={configObj?.data?.page?.formGroupData}
            pageFields={configObj?.data?.page?.page_fields[0]}
            isEdit={isEdit}
            setFormData={setFormData}
            formData={formData}
            appSessionId={appSessionId}
            formTitle={configObj?.data?.page?.form_disclaimer}
            isResponseLoading={submitResponseLoading}
            onSubmitClick={submitButtonClick}
            submitButtonText={isEdit ? "Update" : "Submit"}
            submitButtonIcon={<FontAwesomeIcon icon="fa fa-check-double" />}
            formConfigData={configObj?.data?.page}
          />
        </div>
      </div>
    </div>
  );
};

export default FormComponent;
