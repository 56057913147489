import React, { useEffect, useState } from "react";
import DatasetTable from "../src/components/dataset_editor/dataset_table/DatasetTable";
import DatasetStructure from "../src/components/dataset_editor/dataset_structure/DatasetStructure";
import InstalledApp from "../src/components/application/installedApps/InstalledApp";
import AppList from "../src/components/application/appList/AppList";
import { Route, Switch } from "react-router-dom";
import CreateApp from "../src/components/application/createApp/CreateApp";
import AppDashboard from "../src/components/application/app_dashboard/AppDashboard";
import Visualprogramming from "../src/components/application/createApp/visualProgramming/Visualprogramming";
import ReactUI from "../src/components/react_engine/ReactUI";
import Roles from "./components/roles/Roles";
import AddUser from "./components/addUser/AddUser";
import StandardPrivileges from "./components/privileges/StandardPrivileges";
import CustomizePrivileges from "./components/privileges/CustomizePrivileges";
import { NodeContextProvider } from "./contexts/NodeContext";
import UserProfile from "./components/userProfile/UserProfile";
import Createschedule from "./components/scheduler/createscheduler/Createschedule";
import Schedule from "./components/scheduler/schedule/schedule";
import Maincontent from "../src/components/mainContent/MainContent";
import Schedulehistory from "./components/scheduler/schedulehistory/Schedulehistory";
import ShareApp from "./components/application/shareApp/ShareApp";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import EditPage from "./components/application/createApp/appPages/EditPage";
import ListOfApi from "./components/externalApi/ListOfApi";
import AddNewApi from "./components/externalApi/AddNewApi";
import ApiLogs from "./components/externalApi/ApiLogs";
import Store from "./components/store_management/createStore/Store";
import Category from "./components/store_management/category_listing/Category";
import sub_categories from "./components/store_management/subCategory_listing/SubCategoryLisitng";
import ProductList from "./components/store_management/product_listing/ProductListing";
import AttributeListing from "./components/store_management/attributeSet_listing/AttributeListing";
import AddUpdateProductListing from "./components/store_management/product_listing/AddUpdateProductListing";
import CrudAttribute from "./components/store_management/attributeSet_listing/subAttributes/CrudAttribute";
import CustomField from "./components/store_management/attributeSet_listing/subAttributes/CustomField";
import CompanyProfile from "./components/companyProfile/CompanyProfile";
import Theme from "./components/companyProfile/Theme";
import CategoryCardListing from "./components/store_management/product_listing/category_card/CategoryCardListing";
import AppCategories from "./components/react_engine/store/categories/Categories";
import AppProductList from "./components/react_engine/store/product/ProductList";
import AppProductView from "./components/react_engine/store/product/productView/ProductView";
import AppProductMap from "./components/react_engine/store/product/productMap/ProductMap";
import CalenderComponent from "./components/react_engine/bigCalender/Bigcalender";
import Wishlist from "./components/react_engine/store/wishlist/Wishlist";
import ShareproductList from "./components/common/share/ShareproductList";
import Payment from "./components/payment/Payment";
import Help from "./components/HelpPage/Help";
import StandardLayoutSidemenu from "./components/common/dashboard/standardLayout/StandardLayoutSidemenu";
import SideMenu from "./components/common/sideMenu/SideMenu";
import StandardLayoutHeader from "./components/common/dashboard/standardLayout/StandardLayoutHeader";
import ShareAppHeader from "./components/common/dashboard/standardLayout/ShareAppHeader";
import Header from "./components/common/header/Header";
import Shareproduct from "./components/common/share/Shareproduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ViewCart from "./components/viewCart/ViewCart";
import Search from "./components/search/Search";
import HeaderSearch from "./components/search/HeaderSearch";
import DatasetDataList from "./components/dataset/DatasetDataList";
import { filterThemeData } from "./services/appService";
import { toast } from "react-toastify";

const LoginRoutes = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const initialPrivilege = () => {
    let show = [
      "Roles",
      "Privileges",
      "AddUser",
      "Datasets",
      "Dataset  Editor",
      "My Apps",
      "Available Apps",
      "Create Apps",
      "Create Schedules",
      "Schedules",
      "History / Log",
      "List of Api",
      "Logs",
    ];
    let showComponents = {};
    show.forEach((menu) => {
      showComponents[menu] = {
        read: false,
        write: false,
        readwrite: false,
      };
    });
    showComponents["apps"] = false;
    showComponents["schedule"] = false;
    return showComponents;
  };
  let isUserAdmin =
    JSON.parse(localStorage.getItem("role"))?.toLowerCase() === "admin";
  let userInfo = JSON.parse(localStorage.getItem("user-info"));
  let showComponents = {};
  if (userInfo?.privileges) {
    let { standard_privileges } = userInfo.privileges;

    const showComponent = showComponents;
    standard_privileges?.forEach((data, index) => {
      let key = "";
      let val = {};
      Object.keys(data).forEach((row_key) => {
        key = row_key;
      });
      Object.values(data).forEach((row_val) => {
        val = row_val;
      });
      if (val.read === val.write) {
        val = { ...val, readwrite: val.read || val.write };
      } else {
        val = { ...val, readwrite: val.read || val.write };
      }
      showComponent[key] = val;
    });
    showComponents = showComponent;
  } else {
    showComponents = initialPrivilege();
  }

  const defaultLayout = userInfo?.default_layout;
  const isRedirecting = JSON.parse(localStorage.getItem("isRedirecting"));

  const defaultColors = {
    primaryColor: "#0d6efd",
    secondaryColor: "#607D8B",
    backgroundColor: "#f6f7fb",
    textColor: "#000",
    submitButtonColor: "#198754",
    chooseFileButtonColor: "#0d6efd",
    importButtonColor: "#0dcaf0",
    exportButtonColor: "#0d6efd",
    backButtonColor: "#6c757d",
    addButtonColor: "#6c757d",
    cancelButtonColor: "#dc3545",
    testLogicButtonColor: "#0d6efd",
    uninstallButtonColor: "#dc3545",
    installButtonColor: "#198754",
    boxBackgroundColor : "#ffffff",
    navMenuBackgroundColor : "#ffffff",
    logoBackgroundColor : "#ffffff",
    addToCartButtonColor : "#0d6efd",
    viewWishListButtonColor : "#0d6efd",
    updateFormTitleBackgroundColor : "#f6f7fb",
    closeButtonBackgroundColor : "#f6f7fb",
    activeTabBackgroundColor : "#f6f7fb",
    activeTabBorderBottomColor : "#0d6efd",
    iconColor : "#616161",
    userLogoBackgroundColor : "#5f7d8c",
    formBackgroundColor : "#ffffff",
    tableBackgroundColor : "#ffffff",
    formLabelColor : "#9E9E9E",
    formBorderColor : "#9E9E9E",
    formHeaderColor : "#9E9E9E",
    inputFieldBackgroundColor: "#ffffff",
    inputFieldTextColor: "#607D8B",
    inputFieldBorderColor: "#e9ecef",
    dropdownTextColor : "#607D8B",
    dropdownBorderColor : "#e9ecef",
    dropdownBackgroundColor : "#ffffff",
    dropdownListHoverColor : "#0d6efd",
    tableHeaderBackgroundColor : "#ffffff",
    tableHeaderTextColor : "#607D8B",
    tableRowBackgroundColor : "#ffffff",
    tableRowTextColor : "#607D8B",
    tableBorderColor : "#dfe5e8",
    boxTextColor : "#474747",
    searchButtonColor : "#6c757d",
    formSliderColor : "#0d6efd",
    formSliderShifterColor : "#0d6efd",
  };

  const [loading, setLoading] = useState(true);
  const [colors, setColors] = useState(() => {
    const savedColors = localStorage.getItem("colors");
    // Merge saved colors with defaultColors
    return savedColors
      ? { ...defaultColors, ...JSON.parse(savedColors) }
      : defaultColors;
  });

  const getThemingData = async () => {
    const obj = {
      FilterCriteria: [],
      SortCriteria: [],
      PaginationCriteria: {
        limit: 10,
        skip: 0,
      },
    };
  
    try {
      const response = await filterThemeData(obj);
      if (response && response.data.length > 0) {
        const { _id, ...colorData } = response?.data[0] || {};
        setColors((prev) => {
          const updatedColors = { ...defaultColors, ...prev, ...colorData };
          localStorage.setItem("colors", JSON.stringify(updatedColors));
          return updatedColors;
        });
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty( "--background", colors?.primaryColor );
    root?.style.setProperty( "--bodyBackground", colors?.backgroundColor );
    root?.style.setProperty( "--buttonBackground", colors?.submitButtonColor );
    root?.style.setProperty( "--chooseFileButtonBackground", colors?.chooseFileButtonColor );
    root?.style.setProperty( "--importButtonBackground", colors?.importButtonColor );
    root?.style.setProperty( "--exportButtonBackground", colors?.exportButtonColor );
    root?.style.setProperty( "--backButtonBackground", colors?.backButtonColor );
    root?.style.setProperty( "--addButtonBackground", colors?.addButtonColor );
    root?.style.setProperty( "--cancelButtonBackground", colors?.cancelButtonColor );
    root?.style.setProperty( "--searchButtonBackground", colors?.searchButtonColor );
    root?.style.setProperty( "--testLogicButtonBackground", colors?.testLogicButtonColor );
    root?.style.setProperty( "--uninstallButtonBackground", colors?.uninstallButtonColor );
    root?.style.setProperty( "--installButtonBackground", colors?.installButtonColor );
    root?.style.setProperty( "--boxBackground", colors?.boxBackgroundColor );
    root?.style.setProperty( "--navMenuBackground", colors?.navMenuBackgroundColor );
    root?.style.setProperty( "--logoBackground", colors?.logoBackgroundColor );
    root?.style.setProperty( "--addToCartButtonBackground", colors?.addToCartButtonColor );
    root?.style.setProperty( "--viewWishListButtonBackground", colors?.viewWishListButtonColor );
    root?.style.setProperty( "--updateFormTitleBackground", colors?.updateFormTitleBackgroundColor );
    root?.style.setProperty( "--closeButtonBackground", colors?.closeButtonBackgroundColor );
    root?.style.setProperty( "--reactUiTabActiveBackground", colors?.activeTabBackgroundColor );
    root?.style.setProperty( "--reactUiTabActiveBorderBottomColor", colors?.activeTabBorderBottomColor );
    root?.style.setProperty( "--iconColor", colors?.iconColor );
    root?.style.setProperty( "--userLogoBackground", colors?.userLogoBackgroundColor );
    root?.style.setProperty( "--formBackgroundColor", colors?.formBackgroundColor );
    root?.style.setProperty( "--formLabelColor", colors?.formLabelColor );
    root?.style.setProperty( "--formBorderColor", colors?.formBorderColor );
    root?.style.setProperty( "--formHeaderColor", colors?.formHeaderColor );
    root?.style.setProperty( "--inputFieldBackgroundColor", colors?.inputFieldBackgroundColor );
    root?.style.setProperty( "--inputFieldTextColor", colors?.inputFieldTextColor );
    root?.style.setProperty( "--inputFieldBorderColor", colors?.inputFieldBorderColor );
    root?.style.setProperty( "--tableBackgroundColor", colors?.tableBackgroundColor );
    root?.style.setProperty( "--dropdownTextColor", colors?.dropdownTextColor );
    root?.style.setProperty( "--dropdownBorderColor", colors?.dropdownBorderColor );
    root?.style.setProperty( "--dropdownBackgroundColor", colors?.dropdownBackgroundColor );
    root?.style.setProperty( "--dropdownListHoverColor", colors?.dropdownListHoverColor );
    root?.style.setProperty( "--tableHeaderBackgroundColor", colors?.tableHeaderBackgroundColor );
    root?.style.setProperty( "--tableHeaderTextColor", colors?.tableHeaderTextColor );
    root?.style.setProperty( "--tableRowBackgroundColor", colors?.tableRowBackgroundColor );
    root?.style.setProperty( "--tableRowTextColor", colors?.tableRowTextColor );
    root?.style.setProperty( "--tableBorderColor", colors?.tableBorderColor );
    root?.style.setProperty( "--boxTextColor", colors?.boxTextColor );
    root?.style.setProperty( "--sliderTrackColor", colors?.formSliderColor );
    root?.style.setProperty( "--sliderShifterColor", colors?.formSliderShifterColor );
  }, [colors]);

  useEffect(() => {
    const savedColors = localStorage.getItem("colors");
    if (!savedColors) {
      getThemingData();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <>
      {defaultLayout === "standardLayout" ? (
        <StandardLayoutSidemenu />
      ) : (
        defaultLayout !== "minimalLayout" && <SideMenu />
      )}
      <div
        className={
          defaultLayout === "minimalLayout" || isRedirecting
            ? "ms-0"
            : "right_col"
        }
      >
        {defaultLayout === "standardLayout" ||
        defaultLayout === "minimalLayout" ? (
          <StandardLayoutHeader />
        ) : isRedirecting ? (
          <ShareAppHeader />
        ) : (
          <Header />
        )}

        <div className="row">
          <div className="col-md-12">
            <div className="px-3 pt-2 pb-5 rightMainContainer">
              <div
                className={`scrollToTop ${showScrollToTop ? "show" : ""}`}
                onClick={() => window.scrollTo(0, 0)}
              >
                <FontAwesomeIcon
                  role="button"
                  beatFade={true}
                  icon={"fa fa-angle-up"}
                  size="xl"
                  aria-hidden="true"
                />
              </div>
              <NodeContextProvider>
                <Switch>
                  {showComponents?.Privileges?.readwrite || isUserAdmin ? (
                    <Route
                      path={"/standard-privileges"}
                      exact={true}
                      component={StandardPrivileges}
                    />
                  ) : (
                    <Route
                      path={"/standard-privileges"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents?.Privileges?.readwrite || isUserAdmin ? (
                    <Route
                      path={"/custom-privileges"}
                      exact={true}
                      component={CustomizePrivileges}
                    />
                  ) : (
                    <Route
                      path={"/custom-privileges"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents?.Roles?.readwrite || isUserAdmin ? (
                    <Route path={"/roles"} exact={true} component={Roles} />
                  ) : (
                    <Route path={"/roles"} exact={true} component={ErrorPage} />
                  )}

                  {showComponents?.AddUser?.readwrite || isUserAdmin ? (
                    <Route
                      path={"/add-user"}
                      exact={true}
                      component={AddUser}
                    />
                  ) : (
                    <Route
                      path={"/add-user"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents["Dataset  Editor"]?.readwrite ||
                  isUserAdmin ? (
                    <Route
                      path={"/datasets"}
                      exact={true}
                      component={DatasetDataList}
                    />
                  ) : (
                    <Route
                      path={"/datasets"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents?.Datasets?.readwrite || isUserAdmin ? (
                    <Route
                      path={"/dataset_list"}
                      exact={true}
                      component={DatasetTable}
                    />
                  ) : (
                    <Route
                      path={"/dataset_list"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents?.Datasets?.readwrite || isUserAdmin ? (
                    <Route
                      path={"/dataset/:id"}
                      exact={true}
                      component={DatasetStructure}
                    />
                  ) : (
                    <Route
                      path={"/dataset/:id"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents["Available Apps"]?.readwrite ||
                  isUserAdmin ? (
                    <Route
                      path={"/app-list"}
                      exact={true}
                      component={AppList}
                    />
                  ) : (
                    <Route
                      path={"/app-list"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents["Create Apps"]?.write || isUserAdmin ? (
                    <Route
                      path={"/create-app"}
                      exact={true}
                      component={CreateApp}
                    />
                  ) : (
                    <Route
                      path={"/create-app"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents["List of Api"]?.readwrite || isUserAdmin ? (
                    <Route
                      path={"/list-of-api"}
                      exact={true}
                      component={ListOfApi}
                    />
                  ) : (
                    <Route
                      path={"/list-of-api"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents?.Logs?.readwrite || isUserAdmin ? (
                    <Route
                      path={"/api-logs"}
                      exact={true}
                      component={ApiLogs}
                    />
                  ) : (
                    <Route
                      path={"/api-logs"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents["Create Schedules"]?.write || isUserAdmin ? (
                    <Route
                      path={"/create-schedule"}
                      exact={true}
                      component={Createschedule}
                    />
                  ) : (
                    <Route
                      path={"/create-schedule"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents["Schedules"]?.readwrite || isUserAdmin ? (
                    <Route
                      path={"/schedule"}
                      exact={true}
                      component={Schedule}
                    />
                  ) : (
                    <Route
                      path={"/schedule"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}

                  {showComponents["History / Log"]?.readwrite || isUserAdmin ? (
                    <Route
                      path={"/history-log"}
                      exact={true}
                      component={Schedulehistory}
                    />
                  ) : (
                    <Route
                      path={"/history-log"}
                      exact={true}
                      component={ErrorPage}
                    />
                  )}
                  <Route
                    path={"/app-dashboard"}
                    exact={true}
                    component={AppDashboard}
                  />
                  <Route
                    path={"/app_ui/:id/:flowId?/:storeId?"}
                    exact={true}
                    component={ReactUI}
                  />
                  <Route
                    path={"/share-app"}
                    exact={true}
                    component={ShareApp}
                  />
                  <Route
                    path={"/datatable/:id"}
                    exact={true}
                    component={Maincontent}
                  />
                  <Route
                    path={"/create_app/:app_id"}
                    exact={true}
                    component={CreateApp}
                  />
                  <Route
                    path={"/dataset"}
                    exact={true}
                    component={DatasetStructure}
                  />
                  <Route
                    path={"/installed-apps"}
                    exact={true}
                    component={InstalledApp}
                  />
                  <Route
                    path={"/app_page"}
                    exact={true}
                    component={DatasetStructure}
                  />
                  <Route
                    path={"/add_app_page/:appId"}
                    exact={true}
                    component={DatasetStructure}
                  />
                  <Route
                    path={"/app_page/:id"}
                    exact={true}
                    component={DatasetStructure}
                  />
                  <Route
                    path={"/app_page/page_editor/:id"}
                    exact={true}
                    component={DatasetStructure}
                  />
                  <Route
                    path={"/edit_page/:id"}
                    exact={true}
                    component={EditPage}
                  />
                  <Route path={"/stores"} exact={true} component={Store} />

                  <Route path={"/search"} exact={true} component={Search} />

                  <Route
                    path={"/search/:id/:flowId/:logicId?"}
                    exact={true}
                    component={HeaderSearch}
                  />

                  <Route
                    path={"/store/:store_id"}
                    exact={true}
                    component={Category}
                  />
                  <Route
                    path={"/store/:store_id/categories"}
                    exact={true}
                    component={sub_categories}
                  />
                  <Route
                    path={"/store/:store_id/attributes_set"}
                    exact={true}
                    component={AttributeListing}
                  />
                  <Route
                    path={"/store/:store_id/product_category"}
                    exact={true}
                    component={CategoryCardListing}
                  />
                  <Route
                    path={"/product_list/:store_id/:category_id"}
                    exact={true}
                    component={ProductList}
                  />
                  <Route
                    path={"/product/add-product/:storeId/:category_id"}
                    exact={true}
                    component={AddUpdateProductListing}
                  />
                  <Route
                    path={
                      "/product/product_details/:storeId/:category_id/:product_id"
                    }
                    exact={true}
                    component={AddUpdateProductListing}
                  />
                  <Route
                    path={"/edit-attibuteSet/:storeId/:attributeId"}
                    exact={true}
                    component={CrudAttribute}
                  />
                  <Route
                    path={"/add-attibuteSet/:storeId"}
                    exact={true}
                    component={CrudAttribute}
                  />
                  <Route
                    path={"/custom-attribute-fields/:storeId/:attributeId"}
                    exact={true}
                    component={CustomField}
                  />
                  <Route
                    path={"/user_profile"}
                    exact={true}
                    component={UserProfile}
                  />
                  <Route path={"/cartlist"} exact={true} component={ViewCart} />
                  <Route path={"/help_page"} exact={true} component={Help} />
                  <Route
                    path={"/share_product/:product_id/:tenant_Id"}
                    exact={true}
                    component={Shareproduct}
                  />
                  <Route
                    path={
                      "/share_wishlist_product_list/:tenant_Id/:wishlist_Id"
                    }
                    exact={true}
                    component={ShareproductList}
                  />

                  <Route
                    path={"/add_app_logic/:appId"}
                    exact={true}
                    component={Visualprogramming}
                  />
                  <Route
                    path={"/app_logic/:id"}
                    exact={true}
                    component={Visualprogramming}
                  />
                  <Route
                    path={"/app_logic/logic_editor/:id"}
                    exact={true}
                    component={Visualprogramming}
                  />
                  <Route
                    path={"/add_app_flow/:appId"}
                    exact={true}
                    component={Visualprogramming}
                  />
                  <Route
                    path={"/app_flow/:id"}
                    exact={true}
                    component={Visualprogramming}
                  />
                  <Route
                    path={"/app_flow/flow_builder/:id"}
                    exact={true}
                    component={Visualprogramming}
                  />
                  <Route
                    path={"/update-schedule/:schedule_id"}
                    exact={true}
                    component={Createschedule}
                  />

                  <Route
                    path={"/add-new-api"}
                    exact={true}
                    component={AddNewApi}
                  />
                  <Route
                    path={"/edit-api/:api_id"}
                    exact={true}
                    component={AddNewApi}
                  />
                  <Route
                    path={"/companyprofile"}
                    exact={true}
                    component={CompanyProfile}
                  />
                  <Route
                    path={"/theme"}
                    exact={true}
                    component={Theme}
                  />
                  <Route
                    path={"/app_product_list/:store_id/:category_id"}
                    exact={true}
                    component={AppProductList}
                  />
                  <Route
                    path={"/app_product_view/:product_id"}
                    exact={true}
                    component={AppProductView}
                  />
                  <Route
                    path={"/app_product_map/:store_id/:category_id"}
                    exact={true}
                    component={AppProductMap}
                  />
                  <Route
                    path={"/calender-component"}
                    exact={true}
                    component={CalenderComponent}
                  />
                  <Route
                    path={"/wishlist/:store_id"}
                    exact={true}
                    component={Wishlist}
                  />
                  <Route
                    path={"/shared_product_list/:tenant_Id/:wishlist_Id"}
                    exact={true}
                    component={ShareproductList}
                  />
                  <Route
                    path={"/payment/:payment_session_id"}
                    exact={true}
                    component={Payment}
                  />
                  <Route path={"*"} exact={true}>
                    <h1>404 Error</h1>
                  </Route>
                </Switch>
              </NodeContextProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginRoutes;
