import { React, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import * as $ from "jquery";
import dummyImg from "../../../images/dummy-header-img.png";
import { addToCartList, logOut } from "../../../services/appService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, NavDropdown } from "react-bootstrap";
import { useState } from "react";
import { RoutesContext } from "../../../RoutesContext";

import SearchBar from "../../search/SearchBar";

function Header() {
  const { routesData } = useContext(RoutesContext);
  const [viewCartList, setViewCartList] = useState([]);
  const user = JSON.parse(localStorage.getItem("user-info"))
    ? JSON.parse(localStorage.getItem("user-info"))
    : "";
  const query_data = JSON.parse(localStorage.getItem("query_param"));
  const isAdmin = sessionStorage.getItem("admin-info");
  const history = useHistory();
  const currentTheme = localStorage.getItem("currentTheme");
  const setCurrentTheme = (themeName) => {
    let html = document.getElementsByTagName("html")[0];
    if (
      themeName &&
      themeName !== "" &&
      typeof themeName != "undefined" &&
      themeName !== "undefined"
    ) {
      html.className = themeName;
      localStorage.setItem("currentTheme", themeName);
    }
  };

  const switchTheme = () => {
    let html = document.getElementsByTagName("html")[0];
    if (html.className === "default") {
      setCurrentTheme("dark");
    } else {
      setCurrentTheme("default");
    }
  };
  setCurrentTheme(currentTheme);

  useEffect(() => {
    $("#menu_toggle").on("click", function () {
      $("#main").toggleClass("nav-md nav-sm");
    });
    getAllCartItems();
  }, [routesData?.cartChange]);

  const getAllCartItems = () => {
    addToCartList()
      .then((response) => {
        if (response?.success) {
          let cartData = response?.data;
          setViewCartList(cartData?.product_data || []);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  const loggingOut = (e) => {
    logOut()
      .then((response) => {
        if (response.success === true) {
          if (!isAdmin) {
            localStorage.clear();
            history.push(`/login?tenant_name=${query_data}`);
          } else {
            sessionStorage.clear();
            localStorage.clear();
            history.push("/dmadmin?tenant_name=flashcorp");
          }
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: true, loading: false }, () => {
          toast.error(err.message);
        });
      });
  };

  let userName = user?.Name?.split(" ");
  let firstnameInitial = userName?.[0].charAt(0).toUpperCase();
  let lastName = userName?.[1];
  let lastnameInitial = lastName?.charAt(0).toUpperCase();

  return (
    <div className="top_nav header">
      <div className="nav_menu m-0 d-flex justify-content-between">
        <div className="nav header-icons-container toggle align-items-center ms-2">
          <Link to="#" id="menu_toggle">
            <FontAwesomeIcon icon={"bars"} />
          </Link>
        </div>
        <div className="d-flex align-items-center me-3 header-dropdown">
          <SearchBar />

          <Link
            to={"/cartlist"}
            className="companyProfileHeaderLink mx-2 position-relative p-1"
            title="Cart List"
          >
            <FontAwesomeIcon
              icon={"shopping-cart"}
              className="companyProfileHeaderIcon"
            />
            {viewCartList?.length ? (
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {viewCartList?.length}
              </span>
            ) : null}
          </Link>
          {/* <Button
            onClick={switchTheme}
            className="switchThemeHeaderLink p-1"
            variant="link"
            size="lg"
            title="Switch Theme"
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-half-stroke"}
              className="switchThemeHeaderIcon"
            />
          </Button> */}
          <NavDropdown
            className="me-2 userProfileHeaderDropdown"
            title={
              user.profile_image ? (
                <img
                  title={`${userName?.[0]} ${userName?.[1]}`}
                  src={
                    user.profile_image === undefined ||
                    user.profile_image === ""
                      ? dummyImg
                      : user.profile_image
                  }
                  alt="Profile"
                  className="user_logo"
                />
              ) : (
                <div
                  className="user_logo"
                  title={`${userName?.[0]} ${userName?.[1]}`}
                >
                  <div className="user_logo_text">
                    {firstnameInitial}
                    {lastnameInitial}
                  </div>
                </div>
              )
            }
            id="nav-dropdown"
          >
            <NavDropdown.Item eventKey="4.1">
              <Link className="dropdown-item" to={`/user_profile`}>
                <FontAwesomeIcon icon={"fa fa-user"} />
                <span className="ms-2">User Profile</span>
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="4.2">
              <Link className="dropdown-item" to={"/help_page"}>
                <FontAwesomeIcon icon={"fa fa-handshake"} />
                <span className="ms-1">Help</span>
              </Link>
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="4.3" onClick={loggingOut}>
              <button className="dropdown-item">
                <FontAwesomeIcon icon={"fa fa-sign-out"} />
                <span className="ms-2">Log Out</span>
              </button>
            </NavDropdown.Item>
          </NavDropdown>
          {user?.role_name?.toLowerCase() !== "admin" ? null : (
            <Link
              to={"/companyprofile"}
              className="companyProfileHeaderLink"
              title="Company Profile"
            >
              <FontAwesomeIcon
                icon={"fa fa-gear"}
                className="companyProfileHeaderIcon"
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
