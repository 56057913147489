import React, { Component } from "react";
import { toast } from "react-toastify";
import {
  addNewUser,
  deleteUser,
  getAllUserList,
  getRoleData,
  exportUserList,
  updateUser,
  importUserList,
} from "../../services/appService";
import { getUserDetailsByDatasetName } from "../../services/datasetService";
import Pagination from "react-js-pagination";
import Loader from "../dataset/Loader/Loader";
import "./AddUser.scss";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";
import ReactFileReader from "react-file-reader";
import { CSVLink } from "react-csv";
import CustomModal from "../application/customModal/CustomModal";
export class AddUser extends Component {
  constructor() {
    super();
    this.state = {
      userInfo: JSON.parse(localStorage.getItem("user-info")),
      field: {
        fname: "",
        lname: "",
        email: "",
        password: "",
        contact: "",
        role: "",
        role_name: "",
        managerId: "",
        status: "Active",
      },
      currentPage: 1,
      activePage: 1,
      dataPerPage: 20,
      errors: {},
      getRolesData: [],
      userDataList: [],
      errorMessage: "",
      editState: false,
      totalDataCount: 0,
      loading: true,
      tenant_Id: JSON.parse(localStorage.getItem("tenantId")),
      object_id: "",
      editDropdown: false,
      managerData: [],
      isChanging: false,
      loaderIndex: -1,
      selectedOption: [],
      searchText: "",
      filterCriteriaData: [],
      hidden: true,
      delModal: false,
      delLoader: false,
      exportUserListData: [],
      exportUserListHeader: [
        { label: "_id", key: "_id" },
        { label: "first_name", key: "first_name" },
        { label: "last_name", key: "last_name" },
        { label: "email", key: "email" },
        { label: "id", key: "id" },
        { label: "management_hierarchy", key: "management_hierarchy" },
        { label: "contact", key: "contact" },
        { label: "status", key: "status" },
        { label: "role_name", key: "role_name" },
      ],
    };
    this.csvLinkEl = React.createRef();
  }

  componentDidMount() {
    this.mounted = true;
    this.fetchRolesData();
    this.fetchUserData();
    this.getPrivilages();
    this.fetchManagerData();
  }

  getPrivilages = () => {
    if (this.state.userInfo.privileges) {
      const { app_previleges, data_previleges, standard_privileges } =
        this.state.userInfo.privileges;
      standard_privileges.forEach((data, index) => {
        if (data["AddUser"]) {
          this.setState({ isWrite: data["AddUser"]?.write }, () => {});
        }
        if (data["Datasets"]) {
          this.setState({ isDatasetsWrite: data["Datasets"]?.write }, () => {});
        }
      });
    } else {
      this.setState({ isWrite: true, isDatasetsWrite: true });
    }
  };

  fetchManagerData() {
    getAllUserList()
      .then((response) => {
        if (response.success === true) {
          this.setState({
            managerData: response.data,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });
  }

  fetchUserData = () => {
    let obj = {
      FilterCriteria: this.state.filterCriteriaData,
      SortCriteria: [],
      PaginationCriteria: {
        limit: 20,
        skip: (this.state.currentPage - 1) * 20,
      },
    };
  
    this.setState({ loading: true });
  
    getUserDetailsByDatasetName(obj)
      .then((response) => {
        if (this.mounted) {
          if (response.success === true) {
            let userData = this.state.userInfo.role_name !== "Admin"
              ? response.data.filter((user) => user.role !== "Admin")
              : response.data;
  
            this.setState({
              loading: false,
              userDataList: userData,
              totalDataCount: response.totalcount,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          toast.error(err.response.data.message);
        }
      });
  };
  

  handleUserStatus = (data, i) => {
    this.setState({ loaderIndex: i });
    if (data.status === "Active") {
      data.status = "InActive";
    } else {
      data.status = "Active";
    }
    let userDetails = {
      tenant_id: this.state.tenant_Id,
      is_primary: true,
      fields: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email.toLowerCase(),
        password: data.password,
        role: data.role,
        contact: data.contact,
        manager_id: data.managerId,
        status: data.status,
        role_name: data.role_name,
      },
      object_id: data._id.$oid,
    };

    this.setState({ isChanging: true });
    updateUser(userDetails)
      .then((response) => {
        if (response.success === true) {
          toast.success(response.message);
          this.fetchUserData();
        } else if (response.success === false) {
          toast.error(response.message);
        }
        this.setState({ isChanging: false });
      })

      .catch((err) => {
        toast.error(err.response.message);
      });
  };

  receiveCurrentPageNumber = (pageNumber) => {
    this.setState({ activePage: pageNumber, currentPage: pageNumber }, () => {
      this.fetchUserData();
    });
  };
  
  

  activationStatusHandler = () => {
    let fields = this.state.field;
    if (fields.status === "Active") {
      fields["status"] = "InActive";
    } else {
      fields["status"] = "Active";
    }
    this.setState({ field: fields });
  };

  changeValue(event) {
    let fields = this.state.field;
    if (event.target.name === "role") {
      fields["role_name"] =
        event.target.options[event.target.selectedIndex].text;
      fields[event.target.name] = event.target.value;
    } else {
      fields[event.target.name] = event.target.value;
    }
    this.setState(
      {
        fields,
      },
      () => {
        event.target.value.length !== 0 &&
          this.setState({
            errors: { ...this.state.errors, [event.target.name]: "" },
          });
      }
    );
  }

  toggleShow = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  validateFieldForm() {
    let fields = this.state.field;
    let errors = {};
    let formIsValid = true;

    if (!fields["fname"]) {
      formIsValid = false;
      errors["fname"] = "*Please enter first name.";
    }
    if (!fields["lname"]) {
      formIsValid = false;
      errors["lname"] = "*Please enter last name.";
    }
    var emailPattern = new RegExp(
      /^([A-Za-z0-9._-]+)@([a-z-]+)\.([a-z]{2,3})(\.[a-z]{2})?$/i
    );
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter user email.";
    } else if (!emailPattern.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "*Please enter valid email address.";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter user password";
    }

    if (fields["role"] === "") {
      formIsValid = false;
      errors["role"] = "*Please select role";
    }

    var contactPattern = new RegExp(/^[6-9]{1}[0-9]{9}$/i);
    if (!fields["contact"]) {
      formIsValid = false;
      errors["contact"] = "*Please enter contact number.";
    } else if (!contactPattern.test(fields["contact"])) {
      formIsValid = false;
      errors["contact"] = "*Please enter valid contact number.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  editUser(data) {
    this.clearFields();
    this.setState({
      errors: {},
    });

    this.scrollToTop();
    const { managerData } = this.state;
    const editSelectedOptions = data?.management_hierarchy?.map(
      (managerValue) => {
        const hierarchyData = managerData?.find(
          (manager) => manager.id === managerValue
        );
        return {
          label: `${hierarchyData?.first_name} ${hierarchyData?.last_name}`,
          value: hierarchyData?.id,
        };
      }
    );
    this.setState({
      field: {
        fname: data.first_name,
        lname: data.last_name,
        email: data.email,
        password: data.password,
        role: data.role,
        contact: data.contact,
        managerId: data.manager_id,
        status: data.status,
        role_name: data?.role_name || "",
      },
      selectedOption: editSelectedOptions || [],
      editState: true,
      object_id: data._id.$oid,
    });
  }

  deleteUserDetails(id) {
    this.setState({
      errors: {},
      delLoader: true,
    });
    let obj = {
      data: {
        data: [id],
      },
    };
    {
      this.state.delModal &&
        deleteUser(obj)
          .then((response) => {
            if (response.success) {
              this.setState({
                delModal: false,
              });
              toast.success(response.message);
              if (
                this.state.totalDataCount > 1 &&
                this.state.userDataList.length <= 1
              ) {
                this.setState({
                  currentPage: this.state.currentPage - 1,
                });
              }
              this.fetchUserData();
            }
          })
          .catch((err) => {
            if (err.response) {
              this.setState({
                delModal: false,
              });
              toast.error(err.response.data.message);
            }
          })
          .finally(() => {
            this.setState({
              delLoader: false,
            });
          });
    }
  }

  clearFields() {
    this.setState({
      field: {
        fname: "",
        lname: "",
        email: "",
        password: "",
        contact: "",
        role: "",
        managerId: "",
        status: "InActive",
        role_name: "",
      },
    });
  }

  submitAddUserDetails(event) {
    event.preventDefault();
    if (this.validateFieldForm()) {
      let userDetails = {
        tenant_id: this.state.tenant_Id,
        is_primary: true,
        fields: {
          first_name: this.state.field.fname,
          last_name: this.state.field.lname,
          email: this.state.field.email.toLowerCase(),
          password: this.state.field.password,
          role: this.state.field.role,
          contact: this.state.field.contact,
          management_hierarchy: this.state.selectedOption?.map(
            (option) => option.value
          ),
          status: this.state.field.status,
          role_name: this.state.field.role_name,
        },
        object_id: this.state.object_id ? this.state.object_id : "",
      };
      if (this.state.editState) {
        updateUser(userDetails)
          .then((response) => {
            if (response.success === true) {
              toast.success(response.message);
              this.fetchUserData();
              this.clearFields();
              this.setState({
                editState: false,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message);
            }
          });
      } else {
        addNewUser(userDetails)
          .then((response) => {
            if (response.success === true) {
              toast.success(response.message);
              this.fetchUserData();
              this.clearFields();
              this.setState({
                editState: false,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message);
            }
          });
      }
    }
  }

  fetchRolesData() {
    getRoleData()
      .then((response) => {
        if (this.mounted) {
          if (response.success === true) {
            this.setState({
              getRolesData: response.data,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ errorMsg: true });
      });
  }

  handleAddMultiLevel = () => {
    this.fetchManagerData();
    const array = this.state.selectedOption;
    if (array?.filter((manager) => manager.value === "").length < 1) {
      array?.push({
        label: "",
        value: "",
      });
    } else {
      this.setState({
        errors: { management_hierarchy: "*Please select manager." },
      });
    }
    this.setState({
      selectedOption: array,
    });
  };

  handleRemoveMultiLevel = (data) => {
    this.setState({
      selectedOption: this.state.selectedOption.filter(
        (option) => option !== data
      ),
    });
  };

  handleSelect = (option, index) => {
    const updatedSelectedOption = this.state.selectedOption.map((data, idx) => {
      return idx === index ? option : data;
    });
    this.setState({
      selectedOption: updatedSelectedOption,
      errors: { ...this.state.errors, management_hierarchy: "" },
    });
  };

  // on search filter Data
  fetchUserDetails() {
    if (this.state.searchText.length > 0) {
      let arrValue = [];
      let objData = {
        filter_type: "text",
        type: "contains",
        filter: this.state.searchText,
        filter_field: "first_name",
      };
      arrValue.push(objData);
      this.setState(
        {
          filterCriteriaData: arrValue,
        },
        () => {
          this.fetchUserData();
        }
      );
    } else {
      this.setState(
        {
          filterCriteriaData: [],
        },
        () => {
          this.fetchUserData();
        }
      );
    }
  }

  importUser = (dataFile) => {
    let formData = new FormData();
    formData.append("data_file", dataFile[0]);
    this.setState({ importingUser: true });
    importUserList(formData)
      .then((response) => {
        if (response?.success) {
          this.fetchUserData();
          toast.success(response?.message);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.error_attachment) {
          this.downloadCSV(err?.response?.data?.error_attachment);
        }
        toast.error(err.response.data.message);
      })
      .finally(() => {
        this.setState({ importingUser: false });
      });
  };

  exportUser = () => {
    this.setState({ exportingUser: true });
    exportUserList()
      .then((response) => {
        if (response?.success) {
          let data = response?.data?.map((data) => {
            return { ...data, _id: data?._id?.$oid };
          });
          this.setState({ exportUserListData: data }, () => {
            this.csvLinkEl.current.link.click();
          });
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {
        this.setState({ exportingUser: false });
      });
  };
  downloadCSV = (csvData) => {
    const csvContent = "data:text/csv;charset=utf-8," + csvData;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "error_attachment.csv");
    document.body.appendChild(link);
    link.click();
  };
  render() {
    let options = this.state.managerData
      .map((manager) => {
        return {
          value: manager.id,
          label: `${manager.first_name}   ${manager.last_name}`,
        };
      })
      .filter((filterManager) => {
        if (
          this.state.selectedOption?.filter(
            (selectedManager) => selectedManager.value === filterManager.value
          ).length === 0
        ) {
          return filterManager;
        }
      });

    let rolesData = this.state.getRolesData.map((data, index) => (
      <option key={index} value={data._id.$oid}>
        {data.role_name}
      </option>
    ));

    const { currentPage, dataPerPage } = this.state;

    const indexOfLastRecord = currentPage * dataPerPage;
    const indexOfFirstRecord = indexOfLastRecord - dataPerPage;
    return (
      <div>
        <div className="d-help py-2">
          <h5>Add User</h5>
          <button
            type="button"
            className="back-btn m-0"
            onClick={(e) => {
              e.preventDefault();
              this.props?.history?.goBack();
            }}
          >
            <FontAwesomeIcon icon="fa fa-arrow-left" />
            <span className="ms-1">Back</span>
          </button>
        </div>
        {this.state.isWrite ? (
          <div className="bg-white p-3 border border-secondary border-opacity-25">
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                First Name
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter First Name"
                  name="fname"
                  value={this.state.field.fname}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.fname}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Last Name
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Last Name"
                  name="lname"
                  value={this.state.field.lname}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.lname}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                User Email
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  autocomplete="off"
                  type="email"
                  className="form-control"
                  placeholder="Enter User Email"
                  name="email"
                  value={this.state.field.email}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.email}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Password
              </label>
              <div className="col-md-9 col-sm-9 position-relative">
                <input
                  autocomplete="off"
                  disabled={this.state.editState}
                  type={this.state.hidden ? "password" : "text"}
                  className="form-control"
                  placeholder="Enter User Password"
                  name="password"
                  value={this.state.field.password}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                {!this.state.editState && (
                  <span
                    className={`toggleEyePosition ${
                      this.state.hidden ? "fa fa-eye" : "fa fa-eye-slash"
                    }`}
                    onClick={this.toggleShow}
                  />
                )}

                <div className="errorMsg text-danger">
                  {this.state.errors.password}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-form-label col-md-3 col-sm-3 required">
                Role
              </label>
              <div className="col-md-9 col-sm-9 ">
                <Form.Select
                  name="role"
                  value={this.state.field.role}
                  onChange={(e) => {
                    this.setState({
                      editDropdown: false,
                      selectedOption: [],
                    });
                    this.changeValue(e);
                  }}
                >
                  <option value="">--Select--</option>
                  {rolesData}
                </Form.Select>
                <div className="errorMsg text-danger">
                  {this.state.errors.role}
                </div>
              </div>
            </div>

            {this.state.field.role && (
              <div className="form-group row">
                <div className="d-flex">
                  <label className="col-form-label col-md-3 col-sm-3 ps-0">
                    Manager
                  </label>
                  <div className="m-0 d-flex align-items-center">
                    <span className="mx-2">Add hierarchy levels</span>
                    <FontAwesomeIcon
                      icon={"fa fa-lg fa-plus"}
                      size="xl"
                      className="text-success ms-lg-3 ms-md-3 ms-2 copy-div"
                      aria-hidden="true"
                      onClick={this.handleAddMultiLevel}
                    />
                    <div className="errorMsg text-danger ms-2 m-0 p-0">
                      {this.state.errors.managerId}
                    </div>
                  </div>
                </div>

                {this.state.selectedOption?.length > 0
                  ? this.state.selectedOption?.map((option, index) => {
                      return (
                        <div
                          className="form-group col-12 d-flex mx-0 mb-1"
                          key={index}
                        >
                          <label className="col-form-label col-md-3 col-sm-3 ps-0">
                            {"Level" + " " + (index + 1)}
                          </label>
                          <div className="d-flex flex-column col-12 m-0 p-0">
                            <div className="col-md-8 col-sm-8 dropdown-wrapper d-flex mb-1">
                              <div className="col-lg-12 col-md-12 col-11 p-0 m-0">
                                <Select
                                  options={options}
                                  value={
                                    option.label !== "" ? option : option.label
                                  }
                                  onChange={(e) => this.handleSelect(e, index)}
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                  icon={"fa fa-lg fa-minus"}
                                  size="xl"
                                  className="text-danger ms-lg-3 ms-md-3 ms-2 copy-div"
                                  aria-hidden="true"
                                  onClick={() =>
                                    this.handleRemoveMultiLevel(option)
                                  }
                                />
                              </div>
                            </div>
                            {this.state.selectedOption.length - 1 === index && (
                              <div className="errorMsg text-danger col-md-8 col-sm-8">
                                {this.state.errors.management_hierarchy}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
            )}

            <div className="form-group row">
              <label className="control-label col-md-3 col-sm-3 required">
                Contact
              </label>
              <div className="col-md-9 col-sm-9 ">
                <input
                  type="number"
                  name="contact"
                  className="form-control"
                  placeholder="Enter Contact Number"
                  value={this.state.field.contact}
                  onChange={(e) => {
                    this.changeValue(e);
                  }}
                />
                <div className="errorMsg text-danger">
                  {this.state.errors.contact}
                </div>
              </div>
            </div>

            {this.state.field.role !== "Admin" && (
              <div className="form-group row">
                <label className="control-label col-md-3 col-sm-3">
                  Status
                </label>
                <div className="col-md-9 col-sm-9 statuspointer">
                  <FontAwesomeIcon
                    size="2xl"
                    role="button"
                    className={`stauspointer toggleBtn toggleFormBtnSize ms-0 ${
                      this.state.field.status === "Active"
                        ? "text-success"
                        : "text-danger"
                    }`}
                    icon={
                      this.state.field.status === "Active"
                        ? "fa fa-toggle-on"
                        : "fa fa-toggle-off"
                    }
                    aria-hidden="true"
                    onClick={this.activationStatusHandler}
                  />
                  <div className="errorMsg text-danger">
                    {this.state.errors.status}
                  </div>
                </div>
              </div>
            )}

            <div className="text-end">
              <button
                onClick={() => {
                  this.setState({
                    field: {
                      fname: "",
                      lname: "",
                      email: "",
                      password: "",
                      contact: "",
                      role: "",
                      status: "InActive",
                    },
                    editState: false,
                    errors: {},
                  });
                }}
                className="btn btn-danger btn-md m-0 me-2"
              >
                <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                <span className="ms-1">
                  {this.state.editState ? "Cancel" : "Clear"}
                </span>
              </button>

              <button
                onClick={(event) => this.submitAddUserDetails(event)}
                type="submit"
                className="custom-btn m-0"
              >
                {this.state.editState ? (
                  <>
                    &nbsp;&nbsp;<FontAwesomeIcon icon="fa fa-retweet" />
                    <span className="ms-1">&nbsp;&nbsp;Update User&nbsp;&nbsp;</span>
                  </>
                ) : (
                  <>
                    &nbsp;&nbsp;<FontAwesomeIcon icon="fa-regular fa-circle-check" />
                    <span className="ms-1">&nbsp;&nbsp;Save User&nbsp;&nbsp;</span>
                  </>
                )}
              </button>
            </div>
          </div>
        ) : null}
        <div>
          <div className="d-help pt-3">
            <h5>Saved Users</h5>
          </div>
          <div className="dataset_table saved-user-table d-flex flex-column p-0">
            <div className="mb-2">
              <div className="d-help flex-wrap">
                <div className="mb-1">
                  <div className="input-group input-group-sm mb-0">
                    <span className="input-group-text" id="basic-addon1">
                      <FontAwesomeIcon icon={"fa fa-search"} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search By First Name"
                      name="searchText"
                      value={this.state.searchText}
                      onChange={(e) => {
                        this.setState(
                          {
                            searchText: e.target.value,
                            currentPage: 1,
                          },
                          () => {
                            this.fetchUserDetails();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div>
                  <CSVLink
                    data={this.state?.exportUserListData}
                    headers={this.state.exportUserListHeader}
                    filename={"User List"}
                    ref={this.csvLinkEl}
                  ></CSVLink>
                  <button
                    disabled={this.state.exportingUser}
                    type="button"
                    className="export-btn mb-0 me-1 text-light text-nowrap"
                    onClick={this.exportUser}
                  >
                    {this.state.exportingUser ? (
                      <div className="spinner-border text-light spinner-border-sm" />
                    ) : (
                      <FontAwesomeIcon icon="file-arrow-down" />
                    )}
                    <span className="ms-1">Export</span>
                  </button>

                  <div className="d-inline-block">
                    <ReactFileReader
                      fileTypes={[".csv"]}
                      handleFiles={this.importUser}
                      multipleFiles={false}
                    >
                      <button className="import-btn m-0 text-nowrap">
                        {this.state.importingUser ? (
                          <div className="spinner-border text-light spinner-border-sm" />
                        ) : (
                          <FontAwesomeIcon icon="file-import" />
                        )}{" "}
                        <span className="ms-1">Import</span>
                      </button>
                    </ReactFileReader>
                  </div>
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-hover table-bordered mb-0">
                <thead>
                  <tr className="head-row">
                    <th className="text-nowrap">Sr.no</th>
                    <th className="text-nowrap">User ID</th>
                    <th className="text-nowrap">First Name</th>
                    <th className="text-nowrap">Last Name</th>
                    <th className="text-nowrap">User Email</th>
                    <th className="text-nowrap">Role</th>
                    <th className="text-nowrap">Contact</th>

                    {this.state.isWrite ? (
                      <>
                        <th className="text-nowrap">Status</th>

                        <th className="text-nowrap">Actions</th>
                      </>
                    ) : null}
                  </tr>
                </thead>
                {!this.state.loading ? (
                  <tbody className="table-bordered">
                    {this.state.userDataList.map((data, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1 + indexOfFirstRecord}</td>
                          <td>{data.id}</td>
                          <td>{data.first_name}</td>
                          <td>{data.last_name}</td>
                          <td className="text-nowrap">{data.email}</td>
                          <td>
                            {data.role === "Admin" ? (
                              <span className="fw-bold">Admin</span>
                            ) : (
                              this.state.getRolesData.map((roles) => {
                                if (data.role === roles._id.$oid) {
                                  return roles.role_name;
                                }
                              })
                            )}
                          </td>
                          <td>
                            {data.contact === undefined ? "--" : data.contact}
                          </td>

                          {this.state.isWrite ? (
                            <>
                              <td>
                                {this.state.isChanging ? (
                                  this.state.loaderIndex === i ? (
                                    <div className="spinner-border spinner-border-sm btnLoader"></div>
                                  ) : null
                                ) : data.role !== "Admin" ? (
                                  <FontAwesomeIcon
                                    size="2xl"
                                    role="button"
                                    disabled={true}
                                    className={`stauspointer toggleTableBtnSize ${
                                      data.status === "Active"
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                    icon={
                                      data.status === "Active"
                                        ? "fa fa-toggle-on"
                                        : "fa fa-toggle-off"
                                    }
                                    aria-hidden="true"
                                    name="status"
                                    onClick={() => {
                                      this.handleUserStatus(data, i);
                                    }}
                                  />
                                ) : (
                                  <span className="text-success fw-bold">
                                    Active
                                  </span>
                                )}
                              </td>
                              <td className="text-nowrap">
                                <div className="d-flex text-center m-0">
                                  <div className="action-icons">
                                    <FontAwesomeIcon
                                      icon={"fa fa-pen-to-square me-2"}
                                      role="button"
                                      size="lg"
                                      onClick={() => this.editUser(data)}
                                      className="me-2"
                                    />
                                    {data.role !== "Admin" && (
                                      <FontAwesomeIcon
                                        icon={"fa fa-trash me-2"}
                                        role="button"
                                        size="lg"
                                        onClick={() => {
                                          this.setState({
                                            delUserId: data._id.$oid,
                                            delModal: true,
                                          });
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      );
                    })}
                    {this.state.userDataList.length === 0 && (
                      <tr className="">
                        <td colSpan={7} className="no_table_records">
                          No user found
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={7}>
                        <Loader></Loader>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
            <div className="dynamic_pagination justify-content-between">
              {this.state.totalDataCount > 0 && (
                <div className="my-2">
                  Showing {(this.state.activePage - 1) * 20 + 1} to{" "}
                  {Math.min(this.state.activePage * 20, this.state.totalDataCount)} of{" "}
                  {this.state.totalDataCount} entries
                </div>
              )}
              {this.state.totalDataCount > 10 && !this.state.loading ? (
                <div className="dynamic_pagination my-2">
                  <Pagination
                    pageRangeDisplayed={3}
                    activePage={this.state.activePage}
                    itemsCountPerPage={20}
                    totalItemsCount={this.state.totalDataCount}
                    onChange={this.receiveCurrentPageNumber}
                  />
                </div>
              ) : null}
            </div>            
          </div>
        </div>
        <CustomModal
          modalState={this.state.delModal}
          modalTitle={"Are you sure?"}
          modalDiscription={"Do you want to delete these record?"}
          hideModal={() => this.setState({ delModal: false })}
          onDeleteClick={() => this.deleteUserDetails(this.state.delUserId)}
          isLoading={this.state.delLoader}
        />
      </div>
    );
  }
}

export default AddUser;
