import { useContext ,useEffect, useState } from "react";
import ReactFileReader from "react-file-reader";
import "./customFileUpload.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormContext } from "../../components/react_engine/contexts/FormContext";

const CustomFileUpload = ({ value, onChange, element }) => {
  const [fileUpload, setFileUpload] = useState([]);
  const [fileNameList, setFileNameList] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const { autoFillData } = useContext(FormContext);

  useEffect(() => {
    if (value?.files) {
      setFileUpload(value?.files || []);
      if(value?.fileNames){
        setFileNameList(value?.fileNames?.map((name, index) => name));
      }else{
        setFileNameList(value?.files?.map((_, index) => "File " + (index + 1)));
      }
    } else {
      if (Array.isArray(value)) {
        setFileUpload(value || []);
        setIsDownload(true);
        setFileNameList(
          value?.map((data) => {
            let list = data?.split("/");
            let lastIndex = list[list?.length - 1];
            return lastIndex;
          }) || []
        );
      }
    }
  }, []);

  useEffect(() => {
    if(autoFillData && autoFillData[element.name] && autoFillData[element.name].files && autoFillData[element.name].files.length > 0){
      setFileUpload(autoFillData[element.name]?.files || []);
      if(autoFillData[element.name]?.fileNames){
        setFileNameList(autoFillData[element.name]?.fileNames?.map((name, index) => name));
      }else{
        setFileNameList(autoFillData[element.name]?.files?.map((_, index) => "File " + (index + 1)));
      }
      return;
    }

    if (value?.length === 0) {
      setFileUpload([]);
      setFileNameList([]);
    } else {
      if (Array.isArray(value)) {
        setFileUpload(value);
        setFileNameList(
          value?.map((data) => {
            let list = data?.split("/");
            let lastIndex = list[list?.length - 1];
            return lastIndex;
          }) || []
        );
      }
    }
  }, [value]);

  const removeFile = (delElem) => {
    setFileNameList(fileNameList.filter((_, index) => index !== delElem));
    setFileUpload(fileUpload?.filter((_, index) => index !== delElem));
    onChange({ files: fileUpload?.filter((_, index) => index !== delElem) });
  };

  const handleChange = (e) => {
    let fileList = [];
    Array.from(e.fileList).forEach((files) => {
      fileList.push(files.name);
    });
    setFileNameList([...fileNameList, ...fileList]);
    setFileUpload([...fileUpload, ...e?.base64]);
    const finalFiles = { files: [...fileUpload, ...e?.base64], fileNames: [...fileNameList, ...fileList] };
    onChange(finalFiles);
  };

  const getFileIcon = (extension) => {
    switch (extension) {
      case "pdf":
        return <FontAwesomeIcon className="icons" icon={"file-pdf"} />;
      case "csv":
        return <FontAwesomeIcon className="icons" icon={"file-csv"} />;
      case "xlsx":
        return <FontAwesomeIcon className="icons" icon={"file-excel"} />;
      case "mp4":
      case "mov":
      case "avi":
        return <FontAwesomeIcon className="icons" icon={"file-video"} />;
      default:
        return <FontAwesomeIcon className="icons" icon={"file"} />;
    }
  };

  return (
    <div className="custom-file-upload">
      <ReactFileReader
        base64={true}
        multipleFiles={true}
        handleFiles={handleChange}
        fileTypes="*/*"
      >
        <button className="chooseFile-custom-btn text-nowrap m-1">
          Choose File/s
        </button>
      </ReactFileReader>
      <div className="files">
        {fileNameList.length > 0 && (
          <div className="d-flex flex-wrap">
            {fileNameList?.map((file_name, index) => {
              const file_extension = file_name?.split(".")?.pop();
              return (
                <div key={index}>
                  {["jpg", "jpeg", "png"].includes(file_extension) ? (
                    <div className="d-flex p-1 mx-1 border mb-1 custom-width">
                      {isDownload ? (
                        <a
                          href={fileUpload[index]}
                          download={file_name}
                          className="text-dark text-nowrap"
                          target="_blank"
                        >
                          <img
                            src={fileUpload[index]}
                            alt={file_name}
                            width="30"
                            height="30"
                            className="me-2"
                          />
                          {file_name}
                        </a>
                      ) : (
                        <>
                          <img
                            src={fileUpload[index]}
                            alt={file_name}
                            width="50"
                            height="50"
                          />
                          {file_name}
                        </>
                      )}
                      <FontAwesomeIcon
                        icon="close"
                        className="image-remove"
                        onClick={() => {
                          removeFile(index);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center p-1 mb-1 mx-1 border">
                      <span className="me-1 text-nowrap">
                        {getFileIcon(file_extension)}
                      </span>

                      {isDownload ? (
                        <a
                          href={fileUpload[index]}
                          download={file_name}
                          className="text-dark text-nowrap custom-fileName-width"
                          target="_blank"
                        >
                          {file_name}
                        </a>
                      ) : (
                        <span>{file_name}</span>
                      )}
                      <FontAwesomeIcon
                        icon="close"
                        className="remove-file-icon"
                        onClick={() => {
                          removeFile(index);
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomFileUpload;
