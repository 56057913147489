import React from "react";
import { Modal } from "react-bootstrap";
import {
  assigneeReporterEvaluator,
  backgroundEvaluator,
} from "./kanbanCardUtils";

function KanbanCardDetailedView({
  boardData,
  card,
  displayData,
  hideDetailedKanbanCardModal,
  lane,
  showDetailedKanbanCard,
}) {
  const additionalCardDetails = Object.fromEntries(
    Object.entries(card).filter(([key, value]) => {
      if (!Object.values(displayData?.key_mapping).includes(key)) {
        return value;
      }
    })
  );

  return (
    <Modal
      show={showDetailedKanbanCard}
      onHide={hideDetailedKanbanCardModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {card?.[displayData?.key_mapping?.label]}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {card?.[displayData?.key_mapping?.description] && (
            <div className="d-flex align-items-center m-2 col-12">
              <div className="col-md-3 col-lg-2 col-4">Description</div>
              <div className="kanban-card-description col-md-10 col-lg-10 col-8">
                {card?.[displayData?.key_mapping?.description]}
              </div>
            </div>
          )}

          {card?.[displayData?.key_mapping?.from_section] && (
            <div className="d-flex align-items-center m-2">
              <div className="col-md-3 col-lg-2 col-4">Start Date</div>
              <div className=" kanban-card-from-section col-md-10 col-lg-10 col-8">
                {card?.[displayData?.key_mapping?.from_section]}
              </div>
            </div>
          )}

          {card?.[displayData?.key_mapping?.to_section] && (
            <div className="d-flex align-items-center m-2">
              <div className="col-md-3 col-lg-2 col-4">End Date</div>
              <div className=" kanban-card-to-section col-md-10 col-lg-10 col-8">
                {card?.[displayData?.key_mapping?.to_section]}
              </div>
            </div>
          )}

          {card?.[displayData?.key_mapping?.reporter]?.length && (
            <div className="d-flex align-items-center m-2">
              <div className="col-md-3 col-lg-2 col-4">Reporter</div>
              {card?.[displayData?.key_mapping?.reporter]?.map(
                (reporter, reporterIndex) => {
                  const reporterName = assigneeReporterEvaluator(reporter);
                  const bgParam =
                    boardData?.laneOrder?.indexOf(lane?.id) +
                      reporterIndex +
                      1 || reporterIndex;
                  const backgroundColor = backgroundEvaluator(
                    bgParam,
                    reporter
                  );
                  return (
                    <div className="d-flex align-items-center">
                      <div
                        className={`bg-${backgroundColor} text-white rounded-circle kanban-card-reporter d-flex align-items-center justify-content-center col-md-10 col-lg-10 col-8 me-2`}
                        title={`Reported By: ${reporter}`}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          {reporterName}
                        </div>
                      </div>
                      <div>{reporter}</div>
                    </div>
                  );
                }
              )}
            </div>
          )}

          {card?.[displayData?.key_mapping?.tags]?.length ? (
            <div className="d-flex align-items-center m-2">
              <div className="col-md-3 col-lg-2 col-4">Tags</div>
              <div className="kanban-card-tags d-flex flex-wrap col-md-10 col-lg-10 col-8">
                {card?.[displayData?.key_mapping?.tags]?.map((tag) => {
                  return (
                    <div
                      className={`bg-dark-subtle text-dark rounded p-1 px-2`}
                    >
                      {tag}
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {Object.entries(additionalCardDetails).map(([key, value]) => {
            if (key !== "id") {
              return (
                <div className="d-flex align-items-center m-2">
                  <div className="col-md-3 col-lg-2 col-4 text-capitalize">
                    {key?.split("_")?.length > 0
                      ? key.split("_").join(" ")
                      : key}
                  </div>
                  <div className="col-md-9 col-lg-10 col-8">{value}</div>
                </div>
              );
            }
          })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {Array.isArray(card?.[displayData?.key_mapping?.assignees]) &&  (
          <div className="d-flex m-2 col-12 align-items-center">
            <div className="col-md-2 col-lg-2 col-4 ms-3">Assignees</div>
            <div className="kanban-card-assignees col-md-10 col-lg-10 col-8">
              {card?.[displayData?.key_mapping?.assignees]?.map(
                (assignee, assigneeIndex) => {
                  const assigneeName = assigneeReporterEvaluator(assignee);
                  const bgParam =
                    boardData?.laneOrder?.indexOf(lane?.id) + assigneeIndex ||
                    assigneeIndex;
                  const backgroundColor = backgroundEvaluator(
                    bgParam,
                    assignee
                  );
                  return (
                    <div
                      className={`bg-${backgroundColor} text-white rounded-circle kanban-card-assignee me-2 ${
                        card?.assignees?.length > 10 ? "mt-1" : ""
                      }`}
                      title={`Assigned To: ${assignee}`}
                    >
                      <div className="d-flex align-items-center justify-content-center kanban-card-assignee-name">
                        {assigneeName}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default KanbanCardDetailedView;
