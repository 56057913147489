import React, { useContext, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddUpdateKanbanCard from "./AddUpdateKanbanCard";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  assigneeReporterEvaluator,
  backgroundEvaluator,
  filterFormDataGetter,
} from "./kanbanCardUtils";
import KanbanCardDetailedView from "./KanbanCardDetailedView";
import CustomModal from "../../application/customModal/CustomModal";
import { FilterFormContext } from "../contexts/FilterFormContext";
import { reactAppFormExecutionVP } from "../../../services/API";

const KanbanCard = ({
  card,
  index,
  lane,
  boardData,
  setBoardData,
  displayData,
  objData,
  appSessionId,
  submitFormPayload,
}) => {
  const { filterFormData } = useContext(FilterFormContext);

  const [isUpdatingCard, setIsUpdatingCard] = useState(false);
  const [isDeletingCard, setIsDeletingCard] = useState(false);
  const [showDetailedKanbanCard, setShowDetailedKanbanCard] = useState(false);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);

  const deleteKanbanCard = async (card) => {
    try {
      setShowDeleteLoader(true);
      delete boardData.cards[card?.id];

      const newCardIdsArray = lane?.cardIds?.filter((id) => id !== card?.id);

      const newBoardData = {
        ...boardData,
        lanes: {
          ...boardData.lanes,
          [lane.id]: {
            ...boardData.lanes?.[lane.id],
            cardIds: newCardIdsArray,
          },
        },
        cards: boardData.cards,
      };

      //when the filter is not applied then this data will be send otherwise the filterform data will be send
      let filterformDataToSend = filterFormDataGetter(filterFormData, card);

      const kanbanStructure = {
        kanban_structure: newBoardData,
        card_data: { ...card, deleting: true },
        lane_id: lane?.id,
        card_id: card?.id,
        board_id: newBoardData?.boardId,
        ...filterformDataToSend,
        ...card,
      };

      const newSubmitFormPayload = {
        ...submitFormPayload,
        data: {
          ...submitFormPayload.data,
          formData: [
            {
              ...submitFormPayload.data.formData?.[0],
              ...kanbanStructure,
            },
          ],
        },
      };

      setBoardData(newBoardData);

      const res = await reactAppFormExecutionVP(newSubmitFormPayload);
      if (res.status === 200) {
        setBoardData(newBoardData);
        setShowDeleteLoader(false);
      }
    } catch (error) {
      setBoardData(boardData);
      setShowDeleteLoader(false);
    } finally {
      setShowDeleteLoader(false);
    }
  };

  return (
    <Draggable key={card?.id} draggableId={card?.id} index={index}>
      {(provided) => (
        <div
          className="col-md-12 kanban-card mb-2 rounded"
          key={card?.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className="py-2 m-2"
            onClick={() => setShowDetailedKanbanCard(true)}
          >
            <div className="border-bottom border-secondary border-opacity-25 pb-2">
              <div className="d-flex justify-content-between">
                <div
                  className="d-flex align-items-center m-1"
                  title={card?.[displayData?.key_mapping?.label]}
                >
                  <span className="fw-bold">
                    {card?.[displayData?.key_mapping?.label]}
                  </span>
                </div>

                <div
                  className="kanban-card-action-dropdown d-flex align-items-center justify-content-center"
                  onClick={(e) => e.stopPropagation()}
                >
                  <DropdownButton
                    id="kanban-card-action-button"
                    drop="start"
                    title={
                      <FontAwesomeIcon
                        icon={"fa fa-ellipsis"}
                        title="Actions"
                      />
                    }
                    size="sm"
                  >
                    <Dropdown.Item
                      as="button"
                      onClick={() => {
                        setIsUpdatingCard(true);
                      }}
                    >
                      <div className="kanban-edit-card">
                        <span
                          className="text-success ms-2 cursorPointer"
                          title="Edit"
                        >
                          <FontAwesomeIcon icon={"fa fa-pencil"} />
                        </span>
                        <span className="ms-2">Edit</span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => setIsDeletingCard(true)}
                    >
                      <div className="kanban-delete-card">
                        <span
                          className="text-danger ms-2 cursorPointer"
                          title="Delete"
                        >
                          <FontAwesomeIcon icon={"fa fa-trash"} />
                        </span>
                        <span className="ms-2">Delete</span>
                      </div>
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>

            {card?.[displayData?.key_mapping?.title] && (
              <div className="kanban-card-title fw-semibold m-1">
                {card?.[displayData?.key_mapping?.title]?.slice(0, 50)}
              </div>
            )}

            {card?.[displayData?.key_mapping?.description] && (
              <div className="kanban-card-description m-1">
                {card?.[displayData?.key_mapping?.description]?.length > 100
                  ? `${card?.[displayData?.key_mapping?.description]?.slice(
                      0,
                      100
                    )}...`
                  : card?.[displayData?.key_mapping?.description]}
              </div>
            )}

            {(card?.[displayData?.key_mapping?.from_section] ||
              card?.[displayData?.key_mapping?.to_section]) && (
              <div className="from-to d-flex align-items-center justify-content-between m-1 flex-wrap">
                {card?.[displayData?.key_mapping?.from_section] && (
                  <div className="kanban-card-from-section m-1">
                    {card?.[displayData?.key_mapping?.from_section]}
                  </div>
                )}
                {card?.[displayData?.key_mapping?.to_section] && (
                  <div className="kanban-card-to-section m-1">
                    {card?.[displayData?.key_mapping?.to_section]}
                  </div>
                )}
              </div>
            )}

            {card?.[displayData?.key_mapping?.tags]?.length ? (
              <div className="kanban-card-tags d-flex flex-wrap">
                {card?.[displayData?.key_mapping?.tags]?.map((tag) => {
                  return (
                    <div
                      className={`m-1 bg-dark-subtle text-dark rounded p-1 px-2`}
                    >
                      {tag}
                    </div>
                  );
                })}
              </div>
            ) : null}

            {Array.isArray(card?.[displayData?.key_mapping?.assignees]) && (
              <div className="border-top border-secondary border-opacity-25 pt-2">
                <div className="kanban-card-assignees text-end">
                  {card?.[displayData?.key_mapping?.assignees]?.map(
                    (assignee, assigneeIndex) => {
                      const assigneeName = assigneeReporterEvaluator(assignee);
                      const bgParam =
                        boardData?.laneOrder?.indexOf(lane?.id) +
                          assigneeIndex +
                          index || assigneeIndex;
                      const backgroundColor = backgroundEvaluator(
                        bgParam,
                        assignee
                      );
                      return (
                        <div
                          className={`bg-${backgroundColor} text-white rounded-circle kanban-card-assignee ms-2 ${
                            card?.assignees?.length > 6 ? "my-1" : ""
                          }`}
                          title={`Assigned To: ${assignee}`}
                        >
                          <div className="d-flex align-items-center justify-content-center mx-2 kanban-card-assignee-name">
                            {assigneeName}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>
          <CustomModal
            modalState={isDeletingCard}
            modalTitle={"Are you sure"}
            modalDiscription={"You want to delete this card?"}
            hideModal={() => setIsDeletingCard(false)}
            onDeleteClick={() => deleteKanbanCard(card)}
            isLoading={showDeleteLoader}
          />
          {isUpdatingCard && (
            <AddUpdateKanbanCard
              setIsUpdatingCard={setIsUpdatingCard}
              card={card}
              lane={lane}
              boardData={boardData}
              setBoardData={setBoardData}
              displayData={displayData}
              objData={objData}
              appSessionId={appSessionId}
              submitFormPayload={submitFormPayload}
            />
          )}
          <KanbanCardDetailedView
            key={card?.id}
            boardData={boardData}
            card={card}
            displayData={displayData}
            hideDetailedKanbanCardModal={() => {
              setShowDetailedKanbanCard(false);
            }}
            lane={lane}
            showDetailedKanbanCard={showDetailedKanbanCard}
          />
        </div>
      )}
    </Draggable>
  );
};

export default KanbanCard;
