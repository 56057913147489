import React, { useEffect, useState, useContext } from "react";
import ReactQuill, { Quill } from "react-quill";
import CustomTime from "../../customtime/CustomTime";
import SearchableDropdown from "../../searchableDropdown/SearchableDropdown";
import ToggleSwitch from "../../togglebutton/ToggleButton";
import { inputTypeKey } from "../../common/model/Model";
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { FormContext } from "../contexts/FormContext";
import LineItems from "../lineItems/LineItems";
import "react-quill/dist/quill.snow.css";
import CustomTextBox from "../../custom/CustomTextBox";
import { RoutesContext } from "../../../RoutesContext";
import { handleAutoFillData } from "./ShareAppAutoFill";
import CustomFileUpload from "../../customFileUpload/CustomFileUpload";
import CheckList from "../checkList/CheckList";
import InLineTableForm from "./InLineTableForm";
import CalculatedField from "./fieldsComponent/CalculatedField/CalculatedField";
//import GetEditField from "./GetEditField";
import { v4 as uuId } from "uuid";
import DynamicDependentDropdown from "../dynamicDependentDropdown/DynamicDependentDropdown";
import CheckboxRJSF from "../../checkbox_rjsf/CheckboxRJSF";
import "./customform.scss";
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageResize from "quill-image-resize-module-react";
import CustomSlider from "./CustomSlider";

Quill.register('modules/imageResize', ImageResize);

const CustomForm = ({
  formGroupData,
  pageFields = [],
  formTitle,
  isEdit,
  formData,
  setFormData,
  appSessionId,
  isResponseLoading,
  onSubmitClick,
  submitButtonText,
  submitButtonIcon,
  isFilterForm,
  formConfigData,
}) => {
  const [autoFillData, setAutoFillData] = useState("");
  const [autoFillLoading, setAutoFillLoading] = useState(false);
  const [schema, setSchema] = useState({});
  const [uiSchema, setUiSchema] = useState({});
  const [widgets, setWidgets] = useState({});
  const { routesData } = useContext(RoutesContext);
  const [uidKeyName, setUidKeyName] = useState("");
  const [isQuillModalOpen, setIsQuillModalOpen] = useState(false);
  const [modalFormField, setModalFormField] = useState({});
  const [modalQuillValue, setModalQuillValue] = useState("");

  const handleQuillModalContentChange = (content) => {
    setModalQuillValue(content);
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ 'align': [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
    history: {
      delay: 1000,
      maxStack: 50,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    },
  };

  const toggleQuillModal = (props) => {
    if(props){
      setModalFormField(props);
      if(!isQuillModalOpen){
        setModalQuillValue(props?.value);
      }else{
        props?.onChange(modalQuillValue);
      }
    }else{
      if(!isQuillModalOpen){
        setModalQuillValue(props?.value);
      }else{
        modalFormField?.onChange(modalQuillValue);
      }
    }
    setIsQuillModalOpen(!isQuillModalOpen);
  };

  useEffect(() => {
    createJsonSchema();
    autoFillFormData();
    return () => {
      setSchema({});
      setWidgets({});
      setUiSchema({});
    };
  }, [JSON.stringify(pageFields), isEdit]);

  useEffect(() => {
    if (autoFillData) {
      setFormData({ ...formData, ...autoFillData });
      let data = {};
      pageFields?.map((field) => {
        if (autoFillData[field.name]) {
          if (
            field.data_type === inputTypeKey.searchableDropdown &&
            !Array.isArray(autoFillData[field.name])
          ) {
            data[field.name] = [autoFillData?.[field.name]];
          } else {
            data[field.name] = autoFillData?.[field.name];
          }
        }
      });
      setFormData({ ...formData, ...data });
    }
  }, [autoFillData]);

  const autoFillFormData = () => {
    handleAutoFillData(
      routesData?.shareAppUrl || {},
      formConfigData?.readAutoFillReference,
      appSessionId,
      formConfigData?.autoFillLogic?.label,
      formConfigData?.autoFillReference,
      setAutoFillData,
      setAutoFillLoading
    );
  };

  const createJsonSchema = () => {
    let formJsonArr = {};
    let formUISchemaArr = {};
    let uiJsonSchema = {};
    let requiredFields = [];
    let objectSchema = {};
    let widgets = {};
    let widget = {};

    pageFields?.forEach((element) => {
      let readOnly = element?.initialDisable === "yes";
      if (isEdit === true && element.edit_mode === "no") {
        readOnly = true;
      }

      if (element.data_type === inputTypeKey.textBox) {
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            default: element?.default_value || "",
            readOnly: readOnly,
          },
        };

        if (element?.autoFill === "yes") {
          delete objectSchema[element.name].default;
        }

        widget = {
          [element.name]: (props) => {
            return (
              <CustomTextBox
                readonly={props.readonly}
                value={props.value || ""}
                onChange={(e) => {
                  props.onChange(e);
                  setUidKeyName(uuId() + element.name);
                }}
                elementData={element}
                pageFields={pageFields}
                appSessionId={appSessionId}
              />
            );
          },
        };

        uiJsonSchema = {
          [element.name]: {
            "ui:widget": element.name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.numberTextBox) {
        objectSchema = {
          [element.name]: {
            type: element.data_type,
            title: element.display_name,
            minimum: 0,
            default: Number(element?.default_value || "0"),
            readOnly: readOnly,
          },
        };
        uiJsonSchema = {
          [element.name]: {
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.largeText) {
        let name = element.name;
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            default: element?.default_value || "",
          },
        };

        widget = {
          [name]: (props) => {
            return (
              <>
                <ReactQuill
                  modules={modules}
                  theme="snow"
                  value={props?.value}
                  onChange={(e) => props.onChange(e)}
                />
                <button className="btn btn-secondary btn-sm" onClick={() => toggleQuillModal(props)} style={{ marginTop: '-50px', float: 'right' }}>
                  Pop-out Editor&nbsp;<FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />
                </button>
              </>
            );
          },
        };

        uiJsonSchema = {
          [element.name]: {
            "ui:widget": name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.toggleButton) {
        let defaultData =
          element?.default_value === "true"
            ? element?.checked_text || true
            : element?.unchecked_text || false;
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            default: defaultData,
            readOnly: readOnly,
          },
        };

        widget = {
          [element.name]: (props) => {
            return (
              <ToggleSwitch
                element={element}
                readonly={props.readonly}
                checkedText={element?.checked_text}
                unCheckedText={element.unchecked_text}
                value={props?.value}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
            );
          },
        };

        uiJsonSchema = {
          [element.name]: {
            "ui:widget": element.name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.time) {
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            default: element?.default_value || "",
            readOnly: readOnly,
          },
        };

        widget = {
          [element.name]: (props) => {
            return (
              <CustomTime
                readonly={props?.readonly}
                value={props?.value}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
            );
          },
        };

        uiJsonSchema = {
          [element.name]: {
            "ui:widget": element.name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.searchableDropdown) {
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            default: element?.default_value || formData?.[element.name] || [],
            readOnly: readOnly,
          },
        };

        widget = {
          [element.name]: (props) => {
            return (
              <SearchableDropdown
                readonly={props?.readonly}
                onChange={(e) => {
                  props.onChange(e);
                  setUidKeyName(uuId() + element.name);
                }}
                value={props?.value}
                required={props?.required}
                elementData={element}
                appSessionId={appSessionId}
              />
            );
          },
        };

        uiJsonSchema = {
          [element.name]: {
            "ui:widget": element.name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.dateTime) {
        objectSchema = {
          [element.name]: {
            title: element.display_name,
            type: "string",
            format: "date",
            default: element.default_value || "",
          },
        };

        if (readOnly) {
          uiJsonSchema = {
            [element.name]: {
              "ui:disabled": true,
              "ui:help": element.helpText,
            },
          };
        }
        uiJsonSchema = {
          [element.name]: {
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.checkBox) {
        let boolEnum = element.enter_values?.split(",") || [];

        objectSchema = {
          [element.name]: {
            type: "array",
            title: element.display_name,
            items: {
              type: "string",
              enum: boolEnum,
            },
            uniqueItems: true,
            readOnly: readOnly,
          },
        };

        widget = {
          [element.name]: (props) => {
            return (
              <CheckboxRJSF
                element={element}
                readonly={props.readonly}
                disabled={props.disabled}
                value={props?.value}
                options={props?.options}
                length={props?.length}
                onChange={(e) => {
                  props.onChange(e);
                }}
              />
            );
          },
        };

        uiJsonSchema = {
          [element.name]: {
            "ui:widget": element.name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.singleSelectStaticDropdownlist) {
        let boolEnum = element.enter_values?.split(",") || [];

        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            enum: boolEnum,
            enumNames: boolEnum,
            readOnly: readOnly,
          },
        };

        uiJsonSchema = {
          [element.name]: {
            "ui:widget": "select",
            "ui:placeholder": "--Select--",
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.radioButton) {
        let boolEnum = [];
        let mainData = element.enter_values;
        let boolData = mainData.split(",");
        boolData.forEach(function (obj) {
          boolEnum.push(obj);
        });
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            enum: boolEnum,
            readOnly: readOnly,
            default: element.default_value || "",
          },
        };

        uiJsonSchema = {
          [element.name]: {
            "ui:widget": "radio",
            "ui:options": {
              inline: true,
            },
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.fileUpload) {
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            default: element?.default_value || [],
            readOnly: readOnly,
          },
        };

        widget = {
          [element.name]: (props) => {
            return (
              <CustomFileUpload
                element={element}
                value={props?.value}
                onChange={props?.onChange}
                required={props.required}
                readonly={props?.readonly}
              />
            );
          },
        };

        uiJsonSchema = {
          [element.name]: {
            "ui:widget": element.name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.dynamicDependentDropdown) {
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            readOnly: readOnly,
            default: element.default_value ? [element.default_value] : [],
          },
        };
        widget = {
          [element.name]: (props) => (
            <DynamicDependentDropdown
              field={element}
              value={props.value}
              onChange={props.onChange}
              readonly={props?.readonly}
              required={props?.required}
              elementData={element}
          />
          ),
        };
        uiJsonSchema = {
          [element.name]: {
            "ui:widget": element.name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.checkList) {
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            readOnly: readOnly,
            default: element.default_value || [],
          },
        };
        widget = {
          [element.name]: (props) => (
            <CheckList
              field={element}
              title={element?.display_name}
              onChange={props?.onChange}
              value={props?.value}
              appSessionId={appSessionId}
              isEdit={isEdit}
              disabled={readOnly}
            />
          ),
        };
        uiJsonSchema = {
          [element.name]: {
            "ui:widget": element.name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.numberSlider) {
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
            readOnly: readOnly,
            default: element.default_value || [],
          },
        };
        widget = {
          [element.name]: (props) => (
            <CustomSlider
              field={element}
              title={element?.display_name}
              onChange={props?.onChange}
              value={props?.value}
              appSessionId={appSessionId}
              isEdit={isEdit}
              disabled={readOnly}
            />
          ),
        };
        uiJsonSchema = {
          [element.name]: {
            "ui:widget": element.name,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.customSection) {
        let name = element.name;
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
          },
        };
        widget = {
          [name]: (props) => {
            return (
              <div
                className="fw-bold text-underline"
                dangerouslySetInnerHTML={{ __html: element.htmlCode1 }}
              />
            );
          },
        };
        uiJsonSchema = {
          [element.name]: {
            "ui:widget": name,
            classNames: element.data_type,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.data_type === inputTypeKey.calculatedField) {
        let name = element.name;
        objectSchema = {
          [element.name]: {
            type: "string",
            title: element.display_name,
          },
        };
        widget = {
          [name]: (props) => {
            return (
              <CalculatedField
              field={element}
              title={element?.display_name}
              onChange={props?.onChange}
              value={props?.value}
              appSessionId={appSessionId}
              mainFormData={formData}
              setmainFormData={setFormData}
              />
            );
          },
        };
        uiJsonSchema = {
          [element.name]: {
            "ui:widget": name,
            classNames: element.data_type,
            "ui:help": element.helpText,
          },
        };
      }

      if (element.is_required === true || element.is_required === "true") {
        requiredFields.push(element.name);
      }

      if (element.is_hidden === true || element.is_hidden === "true") {
        uiJsonSchema = {
          [element.name]: {
            "ui:widget": "hidden",
          },
        };
      }

      if (isFilterForm) {
        Object.values(formJsonArr).forEach((formField) => {
          delete formField?.["default"];
        });
      }

      formUISchemaArr = {
        ...formUISchemaArr,
        ...uiJsonSchema,
      };
      formJsonArr = { ...formJsonArr, ...objectSchema };
      widgets = { ...widgets, ...widget };
    });

    setSchema({
      title: formTitle,
      type: "object",
      properties: formJsonArr,
      required: requiredFields,
    });
    setWidgets(widgets);
    setUiSchema(formUISchemaArr);
  };

  return (
    <div className="react_ui">
      <FormContext.Provider
        value={{
          formData,
          setFormData,
          autoFillData,
          setAutoFillData,
          appSessionId,
          uidKeyName,
          setUidKeyName,
          isEdit,
        }}
      >
        {autoFillLoading && (
          <div className="text-center center-loader">
            <div className="loader-circle"></div>
          </div>
        )}
        <Form
          formContext={{
            groupList: formGroupData,
          }}
          templates={{
            ObjectFieldTemplate,
          }}
          validator={validator}
          showErrorList={false}
          noHtml5Validate
          noValidate={true}
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          onChange={(e) => {
            setFormData(e.formData);
          }}
          widgets={widgets}
        >
          <></>
        </Form>
      </FormContext.Provider>

      {pageFields
        ?.filter(
          (data) =>
            data?.data_type === inputTypeKey.inlineTableForm &&
            data.is_hidden !== true
        )
        ?.map((field, index) => (
          <InLineTableForm
            key={index}
            fieldData={field}
            isEdit={isEdit}
            appSessionId={appSessionId}
            title={field?.display_name}
            setInLineTableFormData={(changeData) => {
              setFormData({ ...formData, [field.name]: changeData });
            }}
            inLineTableFormData={formData?.[field.name]}
            mainFormData={formData}
            setmainFormData={setFormData}
          />
        ))}
      {pageFields
        ?.filter(
          (data) => data?.line_items_form_page_fields && data.is_hidden !== true
        )
        ?.map((fields, index) => (
          <LineItems
            key={index}
            title={fields?.display_name}
            name={fields?.name}
            lineItemsFields={fields?.line_items_form_page_fields || []}
            setLineItemData={setFormData}
            lineItemData={formData}
            appSessionId={appSessionId}
          />
        ))}
      {submitButtonText && (
        <div className="text-end">
          <button
            disabled={isResponseLoading}
            className="custom-btn m-0"
            type="button"
            onClick={() => onSubmitClick()}
          >
            {isResponseLoading ? (
              <div className="spinner-border spinner-border-sm text-light"></div>
            ) : (
              submitButtonIcon || ""
            )}
            <span className="ms-1">{submitButtonText}</span>
          </button>
        </div>
      )}
      
      {/* Modal for Fullscreen Editor */}
      <Modal show={isQuillModalOpen} onHide={() => {toggleQuillModal()}} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactQuill
            modules={modules}
            theme="snow"
            value={modalQuillValue}
            onChange={handleQuillModalContentChange}
            style={{ height: '400px' }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
const ObjectFieldTemplate = (props) => {
  const groupList = props?.formContext?.groupList || [];
  const valuesArray =
    groupList
      .map((item) => item.groupFields?.map((field) => field.value))
      .flat() || [];

  return (
    <div>
      <fieldset id="root">
        {props.properties.map((element) => {
          if (!valuesArray.includes(element.name)) {
            return element.content;
          }
        })}
      </fieldset>

      {groupList?.map((group, index) => {
        let groupNames = group.groupFields.map((field) => {
          return field?.value;
        });
        return (
          <React.Fragment key={index}>
            {groupNames.length > 0 && (
              <div className="border mb-3">
                <p className="py-2 px-3 mb-2" >
                  <span className="content">{group.groupName}</span>
                </p>
                <fieldset id="root" className="mb-2 px-3">
                  {props.properties.map((element) => {
                    if (groupNames.includes(element?.name)) {
                      return element.content;
                    }
                  })}
                </fieldset>
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default CustomForm;
